.album_box {
    margin-top: 4rem;
}

.album_box_header {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
}

.album_box_header > .section_header {
    margin: 0;
}

.album_collection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 1rem;
}

.album_button {
    background-color: var(--light-gray);
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    border: none;
    margin-right: 2rem;
    margin-bottom: 2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: var(--shadow);
}

.album_button:hover {
    background-color: var(--light-gray_hov);
}

.album_button.selected {
    background-color: var(--accent-light);
    transition: background-color 0.3s ease;
}

.album_button.selected > p > .plus_icon {
    stroke: var(--light);
}

.album_button.selected > .album_info > p {
    color: var(--light);
    transition: color 0.3s ease;
}

.album_info {
    display: flex;
    flex-direction: column;
    margin: 0 1.5rem;
}

.album_title {
    font-size: 1.5rem;
    color: var(--dark);
    font-weight: 600;
    margin: 0;
    transition: color 0.3s ease;
}

.album_year {
    margin: 0;
    margin-top: 0.5rem;
    color: var(--dark);
    font-weight: 300;
    font-style: italic;
    transition: color 0.3s ease;
}

.album_image {
    width: 75px;
    height: 75px;
    border-radius: 5px;
}

@media only screen and (min-width: 900px) {
    .album_button {
        max-width: 60%;
    }
}