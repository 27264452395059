:root {
    overflow-x: hidden;
}

.body {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#full_header {
    width: 100vw;
    position: relative;
}

#header_art {
    object-fit: cover;
    height: 40vh;
}

#header_text_container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#header_logo_intro {
    height: unset;
    width: 90%;
    margin-bottom: 1rem;
}

.header_title {
    color: var(--light);
    font-weight: 400;
    font-size: 1.6rem;
    margin: 0.4rem 0;
    text-shadow: 2px 2px 4px var(--dark);
    text-align: center;
}

.bold {
    font-weight: 700;
}

.italic {
    font-style: italic;
}

#welcome_body {
    width: 90vw;
    padding-bottom: 5rem;
}

.welcome_title {
    color: var(--dark);
    font-size: 2rem;
    margin: 0;
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.welcome_description {
    color: var(--dark);
    font-size: 1.2rem;
    margin: 0;
}

#login_button_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2rem;
}

.welcome_step {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
}

.welcome_step_text {
    display: flex;
    flex-direction: column;
    color: var(--dark);
    margin-left: 2rem;
}

.welcome_step_title {
    margin: 0;
    font-size: 1.6rem;
    margin-bottom: 1rem;
    font-weight: 400;
}

.welcome_step_description {
    margin: 0;
    font-size: 1.2rem;
}

@media only screen and (min-width: 900px) {
    #welcome_body {
        width: 60vw;
        padding-bottom: 5rem;
    }

    #header_art {
        width: 100%;
    }

    .header_title {
        font-size: 2.5rem;
    }

    #header_logo_intro {
        height: 5rem;
        width: unset;
        margin-bottom: 1rem;
    }
}