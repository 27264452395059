#game {
    margin-bottom: 2rem;
}

#game_controls {
    display: grid;
    grid-template-areas: 
        "button ."
        "text   text";
}

#skip_button {
    grid-area: button;
}

#game_score {
    grid-area: text;
    margin-top: 2rem;
}

.score_text{
    font-size: 1.5rem;
    color: var(--dark);
    font-weight: 400;
    margin: 0;
}

@media only screen and (min-width: 900px) {
    #game_controls {
        display: grid;
        grid-template-areas: 
            "button text"
            ".      text";
        grid-template-columns: auto 1fr;
        grid-template-rows: auto 1fr;
    }

    #game_score {
        margin-left: 2rem;
        margin-top: 0;
    }
}