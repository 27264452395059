#footer {
    width: calc(100vw - 2rem);
    height: 3rem;
    background-color: var(--dark-gray);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    position: fixed;
    bottom: 0;
    left: 0;
}

#footer_text {
    color: var(--light);
    font-size: 1rem;
}

@media only screen and (min-width: 900px) {
    #footer {
        height: 3rem;
        padding: 0 1rem;
    }
}