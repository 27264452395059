#header_div {
    width: 100vw;
    height: 15vh;
    background-color: var(--accent);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

#header_logo {
    height: unset;
    width: 80vw;
}

@media only screen and (min-width: 900px) {
    #header_logo {
        width: unset;
        height: 50% !important;
    }
}