.artist_group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 2rem;
}

.artist_button {
    background-color: var(--light-gray);
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    border: none;
    margin-right: 2rem;
    cursor: pointer;
    box-shadow: var(--shadow);
}

.artist_button:hover {
    background-color: var(--light-gray_hov);
}

.artist_image {
    width: 75px;
    height: 75px;
    border-radius: 50px;
}

.artist_name {
    font-size: 1.5rem;
    color: var(--dark);
    font-weight: 600;
    margin: 0 1.5rem;
}

@media only screen and (min-width: 900px) {
    .artist_image {
        width: 100px;
        height: 100px;
        border-radius: 100px;
    }
}